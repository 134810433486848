import styled from 'styled-components';

export const EditorWrapper = styled.div`
  border: ${props => (props.highlighted === true ? '1px dashed darkviolet' : '')};
  border-radius: ${props => (props.highlighted === true ? '10px' : '')};

  .ql-container {
    font-family: inherit;
    font-size: inherit;
  }

  .ql-editor {
    line-height: inherit;
    text-align: inherit;
    padding: 0;
    overflow-y: inherit;
  }

  .ql-editor p {
    cursor: grab !important
  }

  /* Set dropdown font-families */
  .ql-font span[data-value="ubuntu"]::before {
    font-family: "Ubuntu";
    content: 'Ubuntu' !important;
  }
  .ql-font span[data-value="raleway"]::before {
    font-family: "Raleway";
    content: 'Raleway' !important;
  }
  .ql-font span[data-value="roboto"]::before {
    font-family: "Roboto";
    content: 'Roboto' !important;
  }

  /* Set content font-families */
  .ql-font-ubuntu {
    font-family: "Ubuntu";
  }
  .ql-font-raleway {
    font-family: "Raleway";
  }
  .ql-font-roboto {
    font-family: "Roboto";
  }
  .ql-bubble .ql-tooltip {
    z-index: 10;
  }
`;
